@import "./mixins.scss";
@import "./variables.scss";

.Cennik {
    width: 100%;

    width: 100%;
    min-height: calc(100vh - 4rem - $navbar_height - $navbar_upper_height);

    margin-top: 4rem;

    @include mobile() {
        min-height: calc(100vh - $navbar_height - $navbar_upper_height);
        margin-top: 0px;
    }

    .CennikTableContainer {

        margin-top: 2rem;

        .gridContainer {
            display: grid;
            grid-template-columns: 3fr repeat(3, 1fr);
            /* First column 3 times wider */
        }

        .gridItem {
            padding: 1rem;
            border-bottom: 1px solid #ccc;

            @include mobile() {
                font-size: 0.75rem;
                padding: 0.5rem;
            }
        }

        .OldPrice {
            color: red;
            text-decoration: line-through;
        }

        .bold {
            font-weight: bold;
            text-align: center;
        }
    }

}