@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.myNavbarContainer {
    width: 100%;
    position: fixed;
    top: 0;

    z-index: 999;

    box-shadow: $navbar_shadow;

    .myNavbarUpperContainerWrapper {
        width: 100%;
        height: $navbar_upper_height;

        background: $navbar_upper_bg;
        position: relative;

        color: $navbar_upper_color;

        @include mobile() {
            display: none;
        }

        .myNavbarUpperContainer {
            width: 100%;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            .myNavbarUpperBox {
                height: 100%;

                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 1rem;

                cursor: pointer;


                &:nth-child(4) {
                    background: $button_gold_bg;
                }

                &:nth-child(1) {
                    @include tablet() {
                        display: none;
                    }
                }

                &:nth-child(2) {
                    @include tablet() {
                        display: none;
                    }
                }

                svg {

                    path {
                        stroke: $navbar_upper_color;
                    }
                }

                &:hover {
                    background: $navbar_upper_primary;
                    color: $navbar_upper_primary_hover;

                    svg {
                        path {
                            stroke: $navbar_upper_primary_hover;
                        }
                    }
                }

                .myNavbarUpperText {
                    height: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5rem;

                    font-weight: 400;
                    font-family: 'Cinzel', serif;
                    font-size: 0.95rem;
                    white-space: nowrap;

                    @include tablet() {
                        font-size: 0.75em;
                    }


                }
            }
        }

    }

    .myNavbar {
        height: $navbar_height;

        background: $navbar_bg;

        .myNavbarBrand {
            .myNavbarBrandContainer {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 0.5rem 0.75rem;
            }

            img {
                max-height: unset;
                max-height: calc($navbar_height - 1.5rem);
            }
        }

        .myNavbarBurger {
            color: $primary_color;
            height: $navbar_height;
        }

        .myNavbarMenu {
            @include bulmaTablet {
                background-color: $navbar_upper_primary;
            }
        }

        .myNavbarItem {
            font-weight: 400;
            font-size: 1.2rem;
            font-family: 'Cinzel', serif;

            color: $font_black;
            cursor: pointer;

            padding: 0 1rem;

            border-bottom: 2px solid transparent;

            &:hover {
                border-bottom: 2px solid $font_black;
            }

            @include bulmaTablet() {
                padding: 0.5rem 0.75rem;
                border: unset;

                &:hover {
                    background: $button_gold_bg;
                    border: unset;
                }

            }

        }
    }
}