@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.Footer {
    width: 100%;

    background: $client_opinions_bg;

    .FooterContainer {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        gap: 4rem;

        .RowForLogo {
            width: 100%;
            display: flex;
            gap: 2rem;
            align-items: center;

            .FooterLogo {
                max-width: 90px;
            }

            p {
                font-size: 1.25rem;
                font-weight: 300;
                color: $font_black;
            }
        }

        @include bulmaTablet() {
            flex-wrap: wrap;
            min-height: unset;
        }

        .FooterWrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2rem;

            .FooterCol {
                flex: 1;
                min-width: 300px;
                min-height: 200px;

                @include bulmaTablet() {
                    min-height: unset;
                }

                display: flex;
                flex-direction: column;
                align-items: center;


                .FooterListContainer {
                    width: 100%;

                    ul {
                        display: flex;
                        flex-direction: column;

                        li {
                            max-width: max-content;
                            border-bottom: 1px solid transparent;
                            cursor: pointer;

                            padding: 0.5rem;
                            padding-left: 0.1rem;

                            &:hover {
                                border-bottom: 1px solid $primary_color;
                            }

                            &:first-child {
                                font-weight: 700;
                                cursor: default;

                                &:hover {
                                    border-bottom: 1px solid transparent;
                                }
                            }


                        }

                    }
                }

                .ContactUs {
                    width: 100%;

                    h2 {
                        font-weight: 700;
                        margin-bottom: 0.75rem;
                    }

                    // form {
                    //     width: 100%;
                    //     display: flex;
                    //     flex-direction: column;

                    //     label {
                    //         margin-bottom: 0.25rem;
                    //     }

                    //     input,
                    //     textarea {
                    //         margin-bottom: 1rem;
                    //         background-color: #FFF;
                    //         border-radius: 0px;
                    //         color: $primary-color;
                    //         border: 1px solid $primary_color;

                    //     }

                    //     textarea {
                    //         min-height: 150px;
                    //     }

                    //     button {
                    //         background: $primary-color;
                    //         color: #FFF;

                    //         &:hover {
                    //             background: $button_gold_bg;
                    //             color: $primary-color;
                    //         }
                    //     }
                    // }

                    button {
                        margin-top: 1rem;
                        background: $primary-color;
                        color: #FFF;

                        &:hover {
                            background: $button_gold_bg;
                            color: $primary-color;
                        }
                    }
                }
            }

        }

        .FooterCopyRights {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            flex-wrap: wrap-reverse;

            .FooterCopyRightsButtons {
                display: flex;
                gap: 2rem;

                p {
                    max-width: max-content;
                    border-bottom: 1px solid transparent;
                    cursor: pointer;

                    &:hover {
                        border-bottom: 1px solid $primary_color;
                    }
                }
            }
        }
    }
}