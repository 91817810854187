@import "../styles/variables.scss";
@import "../styles/colors.scss";

.PageLayout {
    width: 100%;
    min-height: 100%;

    .PageGoUp {
        width: 50px;
        height: 50px;

        position: fixed;
        bottom: 1rem;
        right: 1rem;

        z-index: 999;

        padding: 0.5rem;

        border-radius: $border_radius_button;

        box-shadow: $boxes_shadow;

        background-color: #FFF;

        &:hover {
            background: $navbar_upper_primary;
        }

        cursor: pointer;

        svg {
            width: 100%;
            height: 100%;
        }

        transition: right 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
        /* Slide-in animation */
    }

    .Show {
        right: 1rem;
        /* Displayed position */
        opacity: 1;
        /* Visible */
        pointer-events: auto;
        /* Enable pointer events when visible */
    }

    .Hide {
        right: -1rem;
        /* Displayed position */
        opacity: 0;
        /* Visible */
        pointer-events: none;
        /* Enable pointer events when visible */
    }



    .PageLayoutContent {
        width: 100%;
        height: 100%;
    }
}