@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.ClientOpinions {
    width: 100%;
    min-height: 600px;

    background: $client_opinions_bg;

    .OpinionsContainer {
        width: 100%;

        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .OpinionBox {
            min-width: 400px;
            flex: 1;
            max-width: 700px;
            height: 200px;

            background: $client_opinion_box_bg;
            box-shadow: $boxes_shadow;

            display: flex;
            border-radius: 2px;

            @include mobile() {
                min-width: 300px;
                height: unset;
            }

            .OpinionBoxWrapper {
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                padding: 1.5rem;

                .Stars {
                    display: flex;
                    gap: 0.25rem;
                    margin-bottom: 0.5rem;

                    svg {
                        width: 20px;
                        height: 20px;

                        path {
                            stroke: $client_opinion_stars_bg;
                            fill: $client_opinion_stars_bg;
                            stroke-width: 1px;
                        }
                    }
                }

                .OpinionText {
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: $client_opinion_text_color;
                }

                .OpinionClient {
                    padding-top: 3rem;
                    font-size: 0.8rem;
                    align-self: flex-end;
                    justify-self: flex-end;
                    font-family: 'Cinzel', serif;

                    color: $client_opinion_client_color;
                }
            }
        }
    }
}