@import "./colors.scss";
@import "./mixins.scss";
@import "./variables.scss";


.Faq {
    width: 100%;
    min-height: calc(100vh - 4rem - $navbar_height - $navbar_upper_height);

    margin-top: 4rem;

    @include mobile() {
        min-height: calc(100vh - $navbar_height - $navbar_upper_height);
        margin-top: 0px;
    }



    .QuestionsWrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 1rem;

        .QuestionContainer {
            width: 100%;

            border-radius: 4px;

            .QuestionBox {
                width: 100%;
                min-height: 50px;

                background-color: #eee;


                display: flex;
                align-items: center;
                justify-content: space-between;

                padding: 1rem;

                font-size: 1rem;
                font-weight: 400;

                .QuestionArrowBox {
                    min-width: calc(50px - 1rem);
                    min-height: calc(50px - 1rem);

                    background: $button_gold_bg;
                    border-radius: 4px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    user-select: none;

                    cursor: pointer;

                    .rotateSVG {
                        transform: rotate(180deg);
                    }
                }
            }

            .AnswerBox {
                width: 100%;
                max-height: 300px;

                overflow-y: auto;

                padding: 1rem;

                background-color: #FFF;

                font-size: 0.9rem;
                font-weight: 300;

                &.AnswerBoxHidden {
                    display: none;
                }
            }
        }
    }



}