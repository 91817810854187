@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.Prices {
    width: 100%;
    min-height: 200px;

    background-color: $prices_bg;

    .PricesContainer {
        width: 100%;
        min-height: 200px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;

        position: relative;

        .PriceBox {
            min-width: 350px;
            max-width: 350px;
            min-height: 600px;
            max-height: 600px;

            border-radius: $box_radius;

            // border: 1px solid $navbar_upper_primary;

            display: flex;
            flex-direction: column;

            z-index: 5;

            box-shadow: $boxes_shadow;

            background-color: #FFF;

            padding: 8rem 0rem;

            h2 {
                font-weight: 400;
                font-size: 0.9rem;
                text-align: center;
                color: $font_black;

                b {
                    font-weight: 700;
                    font-size: 2.5rem;
                    font-family: 'Cinzel', serif;
                    background: $navbar_bg;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            hr {
                margin: 1rem 2rem;
                background-color: $font_black;
                height: 1px;
            }

            p {
                font-family: 'Cinzel', serif;
                padding: 0rem 2rem;
                font-size: 1rem;
                color: $font_black;

                text-align: center;

                b {
                    font-family: 'Cinzel', serif;
                    font-weight: 700;
                    font-size: 4rem;
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                padding: 0rem 2rem;
                font-size: 0.8rem;

                li {
                    color: $font_black;
                }
            }
        }
    }

    .PricesActions {
        width: 100%;
        min-height: 100px;

        margin-top: 4rem;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;

        text-align: center;
    }
}