@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.PageNotFound {
    width: 100%;

    margin-top: 4rem;

    @include mobile() {
        margin-top: 0px;
    }
}