@mixin mobile {
    @media screen and (max-width: 480px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 768px) {
        @content;
    }
}
@mixin bulmaTablet {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin laptop {
    @media screen and (max-width: 1200px) {
        @content;
    }
}
