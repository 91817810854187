@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.AboutUs {
    width: 100%;

    .AboutUsContainer {
        width: 100%;

        display: flex;
        gap: 1rem;

        @include tablet() {
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }


        .AboutUsImage {
            box-shadow: $boxes_shadow ;
            min-width: 350px;
            max-height: 350px;
            border-radius: calc($border_radius_button);

            display: flex;

            img {
                min-width: 350px;
                max-height: 350px;
            }
        }
    }
}