// Colors
// great color (in logo) #ffefce

// $navbar_bg: #FFF;
// #ae8625
// #f7ef8a
// #d2ac47
// #edc967
$navbar_bg: linear-gradient(90deg, rgba(174, 134, 37, 1) 0%, rgba(247, 239, 138, 1) 31%, rgba(210, 172, 71, 1) 77%, rgba(237, 201, 103, 1) 100%);
$navbar_shadow: 0px 0px 24px rgba(17, 17, 17, 0.5);

$font_black: #222;

// Shadows
$boxes_shadow: -4px 4px 12px #ddd;

// Navbar Upper
$navbar_upper_bg: #FFF;
$navbar_upper_color: #222;
$navbar_upper_primary: #edc967;
$navbar_upper_primary_hover: #222;

$button_gold_bg: linear-gradient(135deg, rgba(247, 239, 138, 1) 17%, rgba(237, 201, 103, 1) 100%);
$button_gold_hover_bg: #222;
$button_gold_shadow: 0px 4px 64px $navbar_upper_primary;

$primary_color: #222;
$onPrimary_color: #170a08; // When primary color is our bg

$gold_color: rgb(226, 199, 48);

// Hero 
$hero_title_bg: $navbar_bg;
$hero_font_color: #FFF;

// Our massages
$our_massages_bg: #FFF;

// Client opinions 
$client_opinions_bg: #faf8f3;
$client_opinion_box_bg: #FFF;
$client_opinion_stars_bg: #edc967;
$client_opinion_text_color: #222;
$client_opinion_client_color: #222;

// Prices 
$prices_bg: #FFF;