@import "./mixins.scss";
@import "./variables.scss";
@import "./colors.scss";


.Voucher {
    width: 100%;
    min-height: calc(100vh - 4rem - $navbar_height - $navbar_upper_height);

    margin-top: 4rem;

    @include mobile() {
        min-height: calc(100vh - $navbar_height - $navbar_upper_height);
        margin-top: 0px;
    }

    .VoucherCard {
        width: 300px;
        height: 180px;

        background: $button_gold_bg;

        border-radius: 26px;

        margin: 2rem auto;

        display: flex;
        align-items: center;
        justify-content: center;

        box-shadow: 0px 4px 6px $navbar_upper_primary;

        img {
            max-width: 120px;
        }
    }

    .VoucherContent {

        display: flex;
        flex-direction: column;
        gap: 1rem;

        text-align: center;

        p {
            font-size: 1rem;

            a {
                color: rgb(88, 117, 232);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        button { 
            max-width: max-content;
            margin: 0 auto;
        }
    }
}