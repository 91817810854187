@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.OurMassages {
    width: 100%;

    background-color: $our_massages_bg;

    .OurMassagesContainer {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 1rem;

        .OurMassageBox {
            min-width: 300px;
            background-color: $our_massages_bg;
            box-shadow: $boxes_shadow;

            border-radius: $box_radius;
            overflow: hidden;

            cursor: pointer;

            transition: all 200ms;

            &:hover {
                transform: scale(1.05);

                .OurMassageInfoBox {
                    background: $button_gold_bg;
                    color: $font_black;

                    svg {
                        path {
                            fill: $font_black;
                        }
                    }
                }
            }

            .OurMassageBoxImageWrapper {
                width: 100%;
                height: 200px;

                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .OurMassageInfoBox {
                display: flex;
                gap: 1rem;

                padding: 1rem;

                h2 {
                    flex: 2;
                    font-weight: 400;
                }

                p {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .OurMassagesActions {
        width: 100%;
        min-height: 50px;

        margin-top: 4rem;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;

        @include mobile() {
            align-items: center;
        }

        button {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }
}