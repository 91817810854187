@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700&display=swap");
// font-family: 'Cinzel', serif;
// font-family: 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap');
// font-family: 'Work Sans', sans-serif;

@import "../styles/variables.scss";
@import "./mixins.scss";

html,
body {
    width: 100%;
    height: calc(100vh - $navbar_height - $navbar_upper_height);
}

html {
    padding-top: calc($navbar_height + $navbar_upper_height);

    @include mobile() {
        padding-top: calc($navbar_height);
    }
}


* {
    font-family: 'Work Sans', sans-serif;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

    stroke-width: 1px;
}

a {
    color: unset;
}

#root {
    width: 100%;
    height: calc(100%);
}