@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

@keyframes breathAnimation {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.15);
    }
}

.Hero {
    background-image: url("../../static/hero_bg_lg.jpg");
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;


    .HeroWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .HeroTextBox {
            padding: 2rem;
            color: $hero_font_color;
            text-align: center;

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;

            img {
                max-width: 125px;
                animation: breathAnimation 3s ease-in-out forwards;
            }

            h1 {
                font-weight: 700;
                font-size: 4rem;
                font-family: 'Cinzel', serif;

                background: $hero_title_bg;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;

                @include tablet() {
                    font-size: 2.5rem;
                }

                @include mobile() {
                    font-size: 1.75rem;
                }
            }

            h2 {
                font-size: 2rem;
                font-weight: 500;

                @include tablet() {
                    font-size: 1.5rem;
                }

                @include mobile() {
                    font-size: 1.25rem;
                }
            }

            p {
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 2rem;
                padding: 0 2rem;
            }
        }
    }
}