@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
@import "./colors.scss";
@import "./mixins.scss";
@import "./variables.scss";

.mySection {
    padding: 4rem 2rem;
}

.mySection-TitleContainer {
    width: 100%;
    min-height: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    margin-bottom: 1rem;

    @include tablet() {
        flex-direction: column;
    }
}

.mySection-center {
    div {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    flex-direction: column;
    text-align: center;

    @include tablet() {
        align-items: center;
    }
}

.mySection-Title {
    font-size: 2rem;
    font-weight: 500;
    font-family: 'Cinzel', serif;
    color: $primary-color;

    @include tablet() {
        font-size: 1.5rem;
    }

    @include mobile() {
        font-size: 1.25rem;
    }
}

.mySection-DescriptionContainer {
    width: 100%;
    min-height: 50px;

    margin-bottom: 1rem;

    .mySection-Description {
        font-size: 0.9rem;
        font-weight: 400;
    }
}

.AboveButtonText {
    font-size: 0.95rem;
    font-weight: 300;
    color: #222;
    letter-spacing: 1px;
}

.TitleIconBox {
    width: 50px;
    height: 50px;

    background: $button_gold_bg;
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 0px 56px $gold_color;
    border-radius: 50px;

    svg {
        width: 24px;
        height: 24px;

        path {
            stroke-width: 1.5px;
            fill: $primary-color;
        }
    }
}

.gold-icon {
    svg {
        path {
            fill: transparent;
            stroke: #222;
        }
    }
}

.HeroBtn {
    border: 0;
    border-radius: $border_radius_button;
    color: $navbar_bg;

    cursor: pointer;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 500;

    background-color: transparent;

    border: 1px solid #FFF;
    color: #FFF;

    padding: 1rem 2rem;

    &:hover {
        background: $button_gold_bg;
        color: $button_gold_hover_bg;
        border-color: transparent;

        box-shadow: $button_gold_shadow;
    }

}

.myNavButton {
    border-radius: $border_radius_button;
    border: 0;
    border: 1px solid $primary_color;
    color: $primary_color;
    padding: 0.5rem 1.5rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;

    font-weight: 600;

    font-family: 'Cinzel', serif;

    cursor: pointer;

    background-color: transparent;

    &:hover {
        background: $button_gold_bg;
        color: $onPrimary_color;
        border-color: transparent;
    }

    display: flex;
    gap: 1rem;
    align-items: center;
}

.onWhiteBtn {
    border: 0;
    color: $navbar_bg;

    border-radius: $border_radius_button;

    cursor: pointer;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 400;
    background-color: $navbar_upper_primary;

    color: $primary-color;

    padding: 1rem 2rem;

    &:hover {
        background: $button_gold_bg;
        color: $button_gold_hover_bg;
    }
}

.error_404 {
    font-size: 10rem;
    font-weight: 700;
    color: #111;
}