@import "./mixins.scss";
@import "./variables.scss";

.Regulamin {
    width: 100%;
    min-height: calc(100vh - 4rem - $navbar_height - $navbar_upper_height);

    margin-top: 4rem;

    @include mobile() {
        min-height: calc(100vh - $navbar_height - $navbar_upper_height);
        margin-top: 0px;
    }

    font-size: 1rem;
    color: #222;
    font-weight: 400;

    h2 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 1rem 0;
    }

    h3 {
        font-size: 1.1rem;
        font-weight: 600;
        margin: 1rem 0;
    }

    ol {
        padding: 1rem;

        ul {
            list-style-type: circle;

            li {
                margin-left: 1rem;
            }

            margin-bottom: 1rem;
        }
    }
}