@import "./colors.scss";
@import "./mixins.scss";
@import "./variables.scss";

.Kontakt {
    width: 100%;

    width: 100%;
    min-height: calc(100vh - 4rem - $navbar_height - $navbar_upper_height);

    margin-top: 4rem;

    @include mobile() {
        min-height: calc(100vh - $navbar_height - $navbar_upper_height);
        margin-top: 0px;
    }

    .KontaktContainer {
        width: 100%;
        min-height: 200px;

        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        margin-top: 4rem;

        @include mobile() {
            margin-top: 2rem;
        }

        .KontaktBox {
            flex: 1;
            min-width: 300px;
            padding: 1rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            gap: 0.5rem;

            .KontaktBoxIcon {
                width: 100px;
                height: 100px;

                background: $client_opinions_bg;
                padding: 1rem;

                border-radius: $box_radius;

                margin-bottom: 2rem;

                svg {
                    width: 64px;
                    height: 64px;
                }
            }



            h2 {
                font-size: 1.2rem;
                font-weight: 600;
            }

            p {
                font-size: 1rem;
                font-weight: 400;
                line-height: 2;

                span {
                    color: red;
                }
            }
        }
    }

    iframe {
        width: 100%;
    }
}