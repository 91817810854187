@import "./mixins.scss";
@import "./variables.scss";

.PelnaOferta {
    width: 100%;
    min-height: calc(100vh - 4rem - $navbar_height - $navbar_upper_height);

    margin-top: 4rem;

    @include mobile() {
        min-height: calc(100vh - $navbar_height - $navbar_upper_height);
        margin-top: 0px;
    }

    .OffersWrapper {
        width: 100%;
        min-height: 100px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 4rem;

        margin-top: 4rem;

        .OfferBox {
            width: 100%;
            min-height: 100px;

            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 2rem;



            @include tablet() {
                flex-direction: column;
                align-items: center;
            }

            &.OfferWrapReverse {
                @include tablet() {
                    flex-direction: column-reverse;
                    align-items: center;
                }
            }

            img {
                flex: 1;
                max-width: 300px;

                @include tablet() {
                    max-width: unset;
                    width: 100%;
                }

                border-radius: 4px;
            }

            .OfferBoxTextContainer {
                .OfferBoxTitle {
                    font-size: 1.25rem;
                }

                hr {
                    margin-top: 0.5rem;
                    height: 1px;
                    background-color: #ddd;
                }

                .OfferDescription {
                    flex: 1;
                    font-size: 1rem;
                    font-weight: 400;
                    color: #666;
                }
            }


        }
    }
}